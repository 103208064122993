
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";

export default Vue.extend({
  name: "Edit",

  directives: { mask },

  data: () => ({
    rules,
    model: {} as any,
    disableSave: true as boolean,
    errorMessages: {} as { [value: string]: Array<string> },
    selects: {
      cities: [] as Array<SelectComponentInterface>
    }
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.branches().getForEdit(Number(to.params.id));
      const cities = await API.cities().getList();
      const users = await API.users().getList();

      next(vm => {
        vm.model = item;
        vm.selects.cities = cities;
        vm.selects.users = users;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          await this.$API
            .branches()
            .edit(Number(this.$route.params.id), { ...this.model });
          await this.$router.push("/branches");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
